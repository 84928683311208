<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__content">
          <div class="footer__left">
            <div class="footer__logo">
              <img src="@/assets/img/logo-white.svg" />
            </div>
            <span class="footer__description">
              Система бронирования косметических услуг
            </span>
            <form @submit.prevent class="footer__subscription-form">
              <div class="footer__subscription-form-inner">
                <input
                  type="email"
                  class="footer__subscription-input"
                  placeholder="Ваш email"
                />
                <button class="btn footer__subscription-btn">
                  Подписаться
                </button>
              </div>
              <div class="footer__subscription-text">
                Подписываясь на новости вы даете согласие на обработку
                персональных данных
              </div>
            </form>
          </div>
          <div class="footer__block footer__menu">
            <div class="footer__menu-inner">
              <div
                v-for="(menuColumn, menuColumnIndex) of menu"
                :key="menuColumnIndex"
                class="footer__menu-inner-part">
                <router-link
                  v-for="(menuItem, menuItemIndex) of menuColumn"
                  :key="menuItemIndex"
                  :to="menuItem.to"
                  class="footer__menu-item">
                  {{ menuItem.title }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="footer__block footer__social">
            <div class="footer__block-title">Способ связи</div>
            <div class="footer__social-inner">
              <a
                v-for="(link, linkIndex) of socials"
                :key="linkIndex"
                :href="link.link"
                target="_blank"
                class="footer__social-link">
                <img :src="link.icon" />
              </a>
            </div>
            <client-only>
              <router-link
                v-if="!isAdmin"
                tag="button"
                to="/business"
                class="footer__add-object">
                <client-only><i class="fas fa-plus"></i></client-only>
                Добавить свой объект
              </router-link>
            </client-only>
          </div>
          <div class="footer__block footer__payment">
            <div class="footer__block-title">Способы оплаты</div>
            <div class="footer__payment-inner">
              <div
                v-for="(payment, paymentIndex) of payments"
                :key="paymentIndex"
                class="footer__payment-item">
                <img :src="payment" />
              </div>
            </div>
          </div>
        </div>
        <div class="footer__author">
          © {{ currentYear }} WEBBERU. All rights reserved
        </div>
      </div>
    </div>
  </footer>

  <FooterMenu />
  <FooterAside />
</template>

<script>
import telegramIcon from "@/assets/img/icon/telegram.svg";
import vkIcon from "@/assets/img/icon/vk.svg";
import instagramIcon from "@/assets/img/icon/instagram.svg";
import emailIcon from "@/assets/img/icon/email.svg";
import visaIcon from "@/assets/img/icon/visa.png";
import masterCardIcon from "@/assets/img/icon/master-card.png";
import mirIcon from "@/assets/img/icon/mir.png";

import FooterAside from "./FooterAside";
import FooterMenu from "./FooterMenu";

export default {
  name: "Footer",
  components: {
    FooterMenu,
    FooterAside,
  },
  data() {
    return {
      showWebdevepCopiryte: import.meta.env.NODE_ENV !== "production",
      menu: [
        [
          {
            to: { path: "/user" },
            title: "Личный кабинет",
          },
          {
            to: { path: "/blog" },
            title: "Медиа",
          },
          {
            to: { path: "/" },
            title: "Решай сам",
          },
          {
            to: { path: "/services" },
            title: "Услуги",
          },
          {
            to: { path: "/about" },
            title: "О Проекте",
          },
        ],
        [
          {
            to: { path: "/conditions" },
            title: "Пользовательское Соглашение",
          },
          {
            to: { path: "/public-offer" },
            title: "Публичная Оферта",
          },
          {
            to: { path: "/contacts" },
            title: "Контакты",
          },
          {
            to: { path: "/tariffs" },
            title: "Тарифы",
          },
        ],
      ],
      socials: [
        {
          link: "https://t.me/webberu",
          icon: telegramIcon,
        },
        {
          link: "https://vk.com/public212836650",
          icon: vkIcon,
        },
        {
          link: "https://instagram.com/webberu.ru?igshid=YmMyMTA2M2Y=",
          icon: instagramIcon,
        },
        {
          link: "mailto:webberu@webberu.ru",
          icon: emailIcon,
        },
      ],
      payments: [
        visaIcon,
        masterCardIcon,
        mirIcon,
      ],
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },

    isAdmin() {
      const accountModule = useAccount();
      return accountModule.isAdmin;
    },
  },
};
</script>

<style lang="scss">
@import "footer";
</style>
