<template>
  <div>
    <div class="aside-menu">
      <div class="aside-menu__inner">
        <span class="aside-menu__close"></span>
        <div class="aside-menu__content">
          <div class="aside-menu__items">
            <div class="aside-menu__item">
              <a href="#" class="aside-menu__link">Личный кабинет</a>
            </div>
            <div class="aside-menu__item">
              <router-link :to="{ path: '/services' }" class="aside-menu__link">
                Услуги
              </router-link>
            </div>
            <div class="aside-menu__item">
              <a href="#" class="aside-menu__link">Салоны</a>
            </div>
            <div class="aside-menu__item">
              <a href="#" class="aside-menu__link">Клиники</a>
            </div>
            <div class="aside-menu__item">
              <router-link to="/media" class="aside-menu__link">
                Медиа
              </router-link>
            </div>
            <div class="aside-menu__item">
              <a href="#" class="aside-menu__link">Форум</a>
            </div>
            <div class="aside-menu__item">
              <a href="#" class="aside-menu__link">«Решай сам»</a>
            </div>
            <div class="aside-menu__item">
              <router-link to="/contacts" class="aside-menu__link">
                Контакты
              </router-link>
            </div>
            <div class="aside-menu__item">
              <router-link to="/about" class="aside-menu__link">
                О Проекте
              </router-link>
            </div>
            <div class="aside-menu__item">
              <a href="#" class="aside-menu__link">Связаться с нами</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="aside-menu__overlay"></div>
  </div>
</template>

<script>
export default {
  name: "FooterAside",
};
</script>
