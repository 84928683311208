<template>
  <div class="mobile-bottom">
    <div class="mobile-bottom__inner">
      <div class="mobile-bottom__blocks">
        <router-link to="/search" class="mobile-bottom__block">
          <div class="mobile-bottom__icon">
            <img src="@/assets/img/icon/search-black-small.svg" alt="" />
          </div>
          <div class="mobile-bottom__name">Найти</div>
        </router-link>
        <router-link to="/user/favorite" class="mobile-bottom__block">
          <div class="mobile-bottom__icon">
            <img src="@/assets/img/icon/favorite-small.svg" alt="" />
          </div>
          <div class="mobile-bottom__name">Избранные</div>
        </router-link>
        <router-link to="/" class="mobile-bottom__block">
          <div class="mobile-bottom__icon">
            <img src="@/assets/img/icon/home-small.svg" alt="" />
          </div>
          <div class="mobile-bottom__name">Главная</div>
        </router-link>
        <router-link to="/user/orders" class="mobile-bottom__block">
          <div class="mobile-bottom__icon">
            <img src="@/assets/img/icon/entries-small.svg" alt="" />
          </div>
          <div class="mobile-bottom__name">Записи</div>
        </router-link>
        <router-link to="/user/profile" class="mobile-bottom__block">
          <div class="mobile-bottom__icon">
            <img src="@/assets/img/icon/user-small.svg" alt="" />
          </div>
          <div class="mobile-bottom__name">Профиль</div>
        </router-link>
        <span v-show="isTimer" class="mobile-bottom__block mobile-bottom__block--sm">
          <Timer
            @timer-start="isTimer = true"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Timer from "@/components/Timer/Timer.vue";

export default {
  name: "FooterMenu",
  components: {
    Timer,
  },
  data() {
    return {
      isTimer: false,
    };
  },
};
</script>
